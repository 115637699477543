import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'

import App from '../components/App'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    console.log(post)
    return (
      <App style={{ background: '#fff' }}>
        <Helmet title={`${post.title}`} />
        {post.heroImage && (
          <Img
            className="heroImage"
            alt={post.title}
            fluid={post.heroImage.fluid}
          />
        )}

        <div className="wrapper">
          <h6
            style={{
              display: 'block',
            }}
          >
            {post.publishDate}
          </h6>
          <h4 className="section-headline">{post.title}</h4>

          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </div>
        <style jsx>{`
          :global(.heroImage) {
            height: 600px;
          }
          @media (max-width: 767px) {
            :global(.heroImage) {
              height: 215px;
            }
          }
          .wrapper {
            padding: 24px;
          }
          .section-headline {
            margin-top: 24px;
            margin-bottom: 48px;
          }
        `}</style>
      </App>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        id
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
